<template>
	<div class="page-with-title">
		<page-title>
      <h1>Correções de letras</h1>
    </page-title>
    <div class="p-4">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div
            v-if="!songs.length"
            class="empty-songs-box"
          >
            <b>Sem correções para aprovação.</b><br>Parece que alguém andou trabalhando bastante heim?!
          </div>
          <table
            v-else
            class="table table-dark"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>ID Música</th>
                <th>Música</th>
                <th>Data de envio</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="song in songs" v-bind:key="song.id">
                <td>{{ song.id }}</td>
                <td>{{ song.id_musica }}</td>
                <td>
                  <router-link :to="{ name: 'songCorrectionShow', params: { id: song.id } }">
                    <b>{{ song?.song?.titulo }}</b> - {{ song?.song?.artista }}
                  </router-link>
                </td>
                <td>{{ song.created_at }}</td>
              </tr>
            </tbody>
          </table>
          <div class="text-center mb-4">
            <button-next-page
              v-if="lastPage >= page"
              @reached="getSongs()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import PageTitle from '@/components/titles/PageTitle'
  import { getSongsPendingCorrection } from '@/api/songs'
  import ButtonNextPage from '@/components/utils/ButtonNextPage'

	export default {
		components: { PageTitle, ButtonNextPage },
    data: () => ({
      songs: [],
      page: 1,
      lastPage: 1
    }),
    mounted() {
      this.getSongs()
    },
    methods: {
      async getSongs() {
        const res = await getSongsPendingCorrection({
          page: this.page++
        })
        res.data.data.map(song => this.songs.push(song))
        this.lastPage = res.data.last_page
      }
    }
	}
</script>


<style scoped lang="scss">
  .empty-songs-box {
    font-size: 32px;
    font-weight: 300;
    text-align: center;
  }
</style>