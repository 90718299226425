import request from '@/utils/request'

 export function getSongs(params) {
   return request({
     url: '/songs',      
     method: 'get',
     params
   })
 }

 export function getSong(id, params) {
   return request({
     url: `/songs/${id}`,
     method: 'get',
     params
   })
 }

 export async function getHighlightBanners() {
   const res = await request({
     url: '/songs/highlights',
     method: 'get'
   })

   return res.data.data
 }

 export async function updateHighlightBanner(banner) {
   const data = new FormData()
   for (let i in banner) {
     data.append(i, banner[i])
   }

   const res = await request({
     url: `/songs/highlights/${banner.id}?recache=1`,
     method: 'post',
     data: data 
   })

   return res.data
 }

 export async function getSongsPendingCorrection(opts) {
  opts = Object.assign({
    page: 1
  }, opts)

  const res = await request({
    url: `/songs/pending-correction?page=${opts.page}`,
    method: 'get'
  })

  return res.data
 }

 export async function getSongPendingCorrection(id) {
  const res = await request({
    url: `/songs/pending-correction/${id}`,
    method: 'get'
  })

  return res.data
 }

 export async function updateSongPendingCorrectionStatus(id, status, data) {
  if (['approved', 'disapproved'].indexOf(status) === -1) {
    throw "Valor incorreto para status da aprovação"
  }

  const res = await request({
    url: `/songs/pending-correction/${id}/status`,
    method: 'post',
    data: { status, ...data }
  })

  return res.data
 }

export async function storeSong(params) {
  const res = await request({
      url: `/lyrics`,
      method: 'post',
      data: params
    })

  return res.data.data
}

export async function updateSong(song) {
  await request({
    url: `/songs/${song.id_musica}`,
    method: 'put',
    data: song
  })
}

export async function searchSongs(query, opts) {
  opts = Object.assign({
   queryParams: {}
  }, opts)

  const res = await request({
    url: 'https://www.letras.com.br/api/search',
    method: 'get',
    params: {
      fields: 'songs',
      q: query,
      ...opts.queryParams
    }
  })

  return res.data.songs
}

export async function getContributionSongsPending(opts) {
 opts = Object.assign({
   page: 1
 }, opts)
 
 const res = await request({
   url: `/contributions/songs/pending?page=${opts.page}`,
   method: 'get'
 })

 return res.data
}

export async function getContributionSong(id) {
  const res = await request({
    url: `/contributions/songs/pending/${id}`,
    method: 'get'
  })

  return res.data
}

export async function approveContributionSong(id, data) {
  const res = await request({
    url: `/contributions/songs/pending/${id}/approval`,
    method: 'post',
    data: data
  })

  return res.data.data
}

export async function disapproveContributionSong(id) {
  const res = await request({
    url: `/contributions/songs/pending/${id}/disapproval`,
    method: 'post'
  })

  return res.data.data
}

export async function deleteSong (id) {
   const res = await request({
     url: `/songs/${id}`,
     method: 'delete'
   })

   return res.data
}