<template>
  <button class="btn btn-icon btn-1 btn-rounded" @click="$emit('reached')">
    <i class="fa fa-plus"></i>
  </button>
</template>

<script>
  export default {

  }
</script>

<style scoped>
  .btn {
    width: 45px;
    height: 45px;
  }
</style>